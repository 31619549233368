<template>
  <div class="article-page">
    <img src="../../assets/image/article-banner.png" style="width: 100%" />
    <div class="article-list-container" style="top: -30px">
      <div>
        <div class="article-title">{{ infoList.title }}</div>
        <div class="article-content" v-html="infoList.notes"></div>
      </div>
      <div class="article-content">
        <template v-if="upInfoList">
          <a :href="'/article-detail/' + upInfoList.id">
            <p>上一篇：<span v-html="upInfoList.title"></span></p>
            <p v-html="upInfoList.notes"></p>
          </a>
        </template>
        <template v-else>
          <p>上一篇：没有了</p>
        </template>
      </div>
      <div class="article-content">
        <template v-if="downInfoList">
          <a :href="'/article-detail/' + downInfoList.id">
            <p>下一篇：<span v-html="downInfoList.title"></span></p>
            <p v-html="downInfoList.notes"></p>
          </a>
        </template>
        <template v-else>
          <p>下一篇：没有了</p>
        </template>
      </div>
    </div>
    <div
      class="article-list-container"
      style="padding: 10px; margin-bottom: 20px"
    >
      <div class="category-title" style="margin-bottom: 10px">
        <span style="font-weight: bold; margin-right: 50px">常见资讯</span>
        <a href="/article-list/9/物流常识" style="font-size: 13px">查看更多</a>
      </div>
      <div v-for="(item, index) in commonList" :key="index" class="zx-list">
        <a :href="'/article-detail/' + item.id"
          >{{ index + 1 }}. {{ item.title }}</a
        >
      </div>
      <div v-if="!commonList" style="text-align: center">没有文章</div>
    </div>
    <div class="article-list-container" style="padding: 10px">
      <div class="category-title" style="margin-bottom: 10px">
        <span style="font-weight: bold; margin-right: 50px">推荐阅读</span>
        <a href="/article-list/9/物流常识" style="font-size: 13px">查看更多</a>
      </div>
      <div v-for="(exo, jn) in readList" :key="jn" class="zx-list">
        <a
          :href="'/article-detail/' + exo.id"
          style="display: flex; justify-content: space-between"
        >
          <span>{{ exo.title }}</span>
          <span> {{ exo.createTime.split(" ")[0] }}</span>
        </a>
      </div>
      <div v-if="!readList" style="text-align: center">没有文章</div>
    </div>
    <!-- 公众号 -->
    <div class="guanzhu">
      <img src="../../assets/image/gzh.jpg" alt="" />
      <div>
        <p>扫一扫，关注我们</p>
        <p>随时随地掌握仓库动态</p>
      </div>
    </div>
  </div>
</template>
<script>
import { courtyIofo } from '../../utils/coupon.js'
export default {
  name: 'articleDetail',
  data() {
    return {
      id: '',
      infoList: {},
      upInfoList: {},
      downInfoList: {},
      pageNum: 1,
      pageSize: 6,
      commonList: [],
      readList: []
    }
  },
  created() {
    this.id = this.$route.params
    this._getList()
    this._getCommonList()
    this._getReadList()
  },
  methods: {
    _getList() {
      this.$reqGet(courtyIofo.detailedList, this.id).then((res) => {
        if (res.data.code === 200) {
          this.infoList = res.data.data.info
          var div = document.createElement("div")
          div.innerHTML = res.data.data.info.notes;
          this.infoList.notes = div.textContent
          this.upInfoList = res.data.data.previous
          this.downInfoList = res.data.data.next
        }
      })
    },
    _getCommonList() {
      const params = {
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }
      this.$reqPost(courtyIofo.questionList, params).then((res) => {
        if (res.data.code === 200) {
          this.commonList = res.data.data.list
        }
      })
    },
    _getReadList() {
      const params = {
        otherType: '12',
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }
      // console.log(params);
      this.$reqPost(courtyIofo.topList, params).then((res) => {
        if (res.data.code === 200) {
          this.readList = res.data.data.list
        }
      })
    },
    tiaozhuan() {
      location.href = '/detail?id=this.$route.params.id'
    }
  }
}
</script>
<style lang="less" scoped>
.article-content {
  padding: 10px;
  font-size: 14px;
  color: #666;
  p {
    margin: 0;
    padding: 0;
  }
}
.category-title {
  display: flex;
  padding: 0px 8px;
  justify-content: space-between;
}
.article-list-container {
  background: #fff;
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 0px 15px #999;
  color: #666;
  margin: 0px 10px;
  .article-title {
    padding-top: 20px;
    font-weight: bold;
    text-align: center;
  }

  .zx-list {
    padding: 5px 10px;
    font-size: 14px;
  }
}
.guanzhu {
  margin: 20px 10px;
  display: flex;
  p {
    font-size: 14px;
    margin: 5px 20px;
  }
}
</style>
